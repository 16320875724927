<template>
  <div>
    <div class="about">
      <div class="text-h6 mb-1 font-weight-bold">{{ $t('AboutDynamic.Title') }}</div>
      <div class="mb-8" style="width:26px; height:2px; background-color:#AC8250"></div>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" v-for="item in newsList" :key="item.id">
          <v-card elevation="0" class="d-block d-sm-flex justify-start pa-6 mb-6">
            <div v-if="newsil8n == 'ja'" class="ml-0 ml-sm-6 pt-3">
              <div class="grey--text text-body-2">{{ item.createdAt | sliceStr }}</div>
              <div class="text-h5  text-clamp mt-4">{{ item.titleJap }}</div>
              <div class="text-caption  text-clamp3 mt-2 mb-6">{{ item.keywordsJap }}</div>
              <a :href="item.urlJap" class="black--text"><span class="pointer text-body-1">Read more <v-icon
                    color="black">mdi-arrow-right-bold</v-icon></span></a>
            </div>
            <div v-if="newsil8n == 'zh'" class="ml-0 ml-sm-6 pt-3">
              <div class="grey--text text-body-2">{{ item.createdAt | sliceStr }}</div>
              <div class="text-h5  text-clamp mt-4">{{ item.title }}</div>
              <div class="text-caption  text-clamp3 mt-2 mb-6">{{ item.keywords }}</div>
              <a :href="item.url" class="black--text"><span class="pointer text-body-1">Read more <v-icon
                    color="black">mdi-arrow-right-bold</v-icon></span></a>
            </div>
            <div v-if="newsil8n == 'en'" class="ml-0 ml-sm-6 pt-3">
              <div class="grey--text text-body-2">{{ item.createdAt | sliceStr }}</div>
              <div class="text-h5  text-clamp mt-4">{{ item.titleEn }}</div>
              <div class="text-caption  text-clamp3 mt-2 mb-6">{{ item.keywordsEn }}</div>
              <a :href="item.urlEn" class="black--text"><span class="pointer text-body-1">Read more <v-icon
                    color="black">mdi-arrow-right-bold</v-icon></span></a>
            </div>
            <div v-if="newsil8n == 'ko'" class="ml-0 ml-sm-6 pt-3">
              <div class="grey--text text-body-2">{{ item.createdAt | sliceStr }}</div>
              <div class="text-h5  text-clamp mt-4">{{ item.titleKor }}</div>
              <div class="text-caption  text-clamp3 mt-2 mb-6">{{ item.keywordsKor }}</div>
              <a :href="item.urlKor" class="black--text"><span class="pointer text-body-1">Read more <v-icon
                    color="black">mdi-arrow-right-bold</v-icon></span></a>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-pagination class="mt-10" color="#AC8250" v-model="params.current" :total-visible="10" :length="pageLength"
        @input="getlist" @next="nextPage" @prev="prevPage"></v-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue'
import { get } from "../../request/http.js";

export default {
  data() {
    return {
      newsil8n: 'ja',
      params: {
        current: 1,
        pageSize: 8
      },
      pageNum: 0,
      pageLength: 0,
      newsList: null,
    }
  },
  components: {
    Footer
  },
  methods: {
    // 分页下一页
    nextPage: function () {
      this.params.current + 1
    },
    // 分页上一页
    prevPage: function () {
      this.params.current - 1
    },
    getlist: async function () {
      await get(`https://capi.supercells.pro/app/api/post/pc/list?query={"current":${this.params.current},"pageSize":${this.params.pageSize}}`, this.params).then(res => {
        if (res.status == 200) {
          this.pageNum = res.data.data.current;
          this.pageLength = Math.ceil(res.data.data.total / this.params.pageSize);
          this.newsList = res.data.data.data;
        }
      })
    }
  },
  mounted() {
    this.newsil8n = this.$i18n.locale
    this.getlist()
  },
  watch: {
    '$i18n.locale'(newil8n) {
      this.newsil8n = newil8n;
    },
  },
  filters: {
    sliceStr(str) {
      return str.slice(0, 10)
    }
  }
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.text-caption {
  line-height: none !important;
}

.about {
  min-height: calc(100vh - 289px);
  padding: 80px 120px;
  background: #FCF9F6;
}

.text-clamp {
  word-break: break-all;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.text-clamp3 {
  min-height: 60px;
  word-break: break-all;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.page-container {
  min-height: calc(100vh - 289px);
  padding: 120px;
  background: #F8F4F0;
}

@media screen and (max-width: 960px) {
  .about {
    padding: 30px 24px;

    .activity-img {
      width: 100% !important;
      height: auto !important;
    }
  }

  .page-container {
    min-height: calc(100vh - 266px);
    padding: 80px 24px 30px;
  }
}
</style>
  